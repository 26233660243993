import Vue from 'vue';

export default Vue.extend({
	data()
	{
		return {
			variables: {
				pathname: this.$route.path
			}
		};
	},
	methods:
	{
		HANDLE_HOME(): void
		{
			this.$router.push({ name: 'home' }).catch(() => {});
		}
	}
});