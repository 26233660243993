




















import ViewModel from '@/views/NotFound.ts';
export default ViewModel;
